import React, { Component } from 'react'
import { v4 as uuid } from "uuid"
import { TextField, Button } from "@material-ui/core"

export class NewCard extends Component {
    state = {
        value: "",
        color: "white"
    }

    constructor(props) {
        super(props)
        this.handleChange = this.handleChange.bind(this)
        this.textInput = React.createRef()
    }

    onSubmit(e) {
        e.preventDefault()
        const color = this.state.color
        const value = this.state.value.trim()
        if (!value) {
            return false
        }

        this.props.addCard(value, color, uuid())
        this.textInput.current.focus()
        this.setState(() => ({
            value: ""
        }))
    }

    handleChange(value, property) {
        this.setState(() => ({
            [property]: value
        }))
        if (property === "color") {
            this.textInput.current.focus()
        }
    }

    render() {
        return (
            <div className="NewCard">
                <form onSubmit={(e) => { this.onSubmit(e) }}>
                    <div className="flex-row">
                        <div className="color-selections">
                            <div className="flex-row color-selection white">
                                <input
                                    autoCapitalize="false"
                                    autoCorrect="false"
                                    autoComplete="false"
                                    onChange={() => { this.handleChange("white", "color") }} type="radio" name="color" value="white" id="white" checked={this.state.color === "white"} />
                                <label htmlFor="white">White</label>
                            </div>
                            <div className="flex-row color-selection black">
                                <input onChange={() => { this.handleChange("black", "color") }} type="radio" name="color" value="black" id="black" checked={this.state.color === "black"} />
                                <label htmlFor="black">Blue</label>
                            </div>
                        </div>
                    </div>
                    <div className="flex-row input-and-submit">
                        <TextField id="outlined-basic" label="Card" variant="outlined" ref={this.textInput}
                            value={this.state.value}
                            onChange={(e) => {
                                this.handleChange(e.target.value, "value")
                            }}
                            autoCapitalize="none"
                            autoCorrect="none"
                            autoComplete="none"
                            autoFocus
                        />
                        {                        // <input autoFocus name="text" type="text" ref={this.textInput}
                            //     value={this.state.value}
                            //     onChange={(e) => {
                            //         this.handleChange(e.target.value, "value")
                            //     }} />
                        }
                        <Button type="submit" variant="contained" color="primary" disabled={!this.state.value}>
                            Add
                        </Button>
                        {// <button disabled={!this.state.value} type="submit">Add</button>
                        }
                    </div>
                </form>
            </div>
        )
    }
}

export default NewCard
