import React, { Component } from 'react'
import Card from "./Card"
import NewCard from './NewCard'
import { v4 as uuid } from "uuid"

export class Cards extends Component {
    state = {
        cards: [
            {
                text: "The gimatriyah of your name, minus one",
                color: "white",
                _id: "1",
                // uuid: ""
            },
            {
                text: "And so, armed with nothing but _____ , Yeruchom set out to become  _____ .",
                color: "black",
                _id: "2",
                // uuid: ""
            },

        ] // array of objects
    }

    // checkDuplicate() {
    //     if
    // }

    componentDidMount() {
        this.setState((prevState) => {
            const cards = prevState.cards

            cards.forEach((cardObj) => {
                const id = uuid()
                cardObj._id = id
                // cardObj.uuid = id
            })

            return {
                cards
            }
        })
    }


    addCard = (text, color, _id) => {
        this.setState((prevState) => ({
            cards: [{
                text, color, _id
            }, ...prevState.cards]
        }))
    }

    removeCard = (_id) => {
        this.setState((prevState) => {

            console.log(prevState.cards);

            return ({
                cards: prevState.cards.filter((card) => (card._id !== _id))
            })
        })
    }

    render() {
        return (
            <div className="add-new">
                <NewCard addCard={this.addCard} />
                <div className="Cards">
                    {
                        this.state.cards.map((card) => (
                            <Card key={card._id} uuid={card._id}
                                text={card.text} color={card.color}
                                removeCard={this.removeCard} />
                        ))
                    }
                </div>
            </div>
        )
    }
}

export default Cards
