import React, { Fragment } from 'react'
import './index.scss'

import Cards from "./components/Cards"
import Social from "./components/Social"

const Header = () => (
  <header>
    <nav>
      <a href="https://freidomfighter.com">Back to home</a>
      <h1>Kiruv vs. Orthodoxy</h1>
    </nav>
  </header>
)

const App = () => {
  return (
    <Fragment>
      <Header />
      <main>
        <Cards />
        <Social />
      </main>
    </Fragment>
  )
}

export default App;
