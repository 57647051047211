import React, { Component } from 'react'
import axios from "axios"
import Card from "./Card"
import NewCard from './NewCard'
import { v4 as uuid } from "uuid"

export class Social extends Component {
    state = {
        cards: [
            // {
            //     text: "Read this book. It'll give you _____ .",
            //     color: "black",
            //     key: "2",
            // },
            // {
            //     text: "The gimatriyah of your name, minus one",
            //     color: "white",
            //     key: "1",
            // },

        ] // array of objects
    }

    async componentDidMount() {
        const cards = await (await fetch("http://localhost:9000/getcards")).json()
        console.log(cards);
        this.setState(() => {
            return {
                cards: cards
            }
        })
    }

    render() {
        const blackCardArray = this.state.cards.black
        console.log(blackCardArray);
        const whiteCardArray = this.state.cards.white
        return (
            <div className="Social">
                <h2>Vote on your favourite cards</h2>
                <div className="Cards">
                    {
                        blackCardArray && <Card key={blackCardArray[0]._id} uuid={blackCardArray[0]._id} text={blackCardArray[0].text} color={blackCardArray[0].color} removeCard={this.removeCard} />
                    }
                    {
                        whiteCardArray && <Card key={whiteCardArray[0]._id} uuid={whiteCardArray[0]._id} text={whiteCardArray[0].text} color={whiteCardArray[0].color} removeCard={this.removeCard} />
                        // // (() => {
                        // //     for (const [key, value] of Object.entries(this.state.cards)) {
                        // //         console.log(value);
                        // //         return (
                        // //             <Card key={value[0]._id} uuid={value[0]._id} text={value[0].text} color={value[0].color} removeCard={this.removeCard} />
                        // //         )
                        // //     }
                        // // })()

                        // Object.entries(this.state.cards).map((card) => {
                        //     if (typeof card === "object") {
                        //         return (
                        //             <Card key={card[0]._id} uuid={card[0]._id} text={card[0].text} color={card[0].color} removeCard={this.removeCard} />
                        //         )
                        //     }
                        // })
                    }
                </div>
            </div>
        )
    }
}

export default Social
