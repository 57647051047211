import React, { Component } from 'react'
import black from "../assets/black.png"
import white from "../assets/white.png"
import ArvoBold from "../assets/Arvo-Bold.ttf"

import { saveAs } from 'file-saver'

import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";

// import drawMultilineText from "canvas-multiline-text"

export class Card extends Component {
    state = {
        src: "",
        text: this.props.text,
        _id: this.props.uuid
    }

    constructor(props) {
        super(props)
        this.canvas = React.createRef()
    }

    componentDidMount() {
        const canvas = this.canvas.current
        const context = canvas.getContext("2d")
        const image = new Image()
        image.src = this.props.color === "white" ? white : black
        image.onload = () => {
            context.drawImage(image,
                0, 0, image.width, image.height,
                0, 0, canvas.width, canvas.height
            )

            const f = new FontFace("Arvo", `url(${ArvoBold})`)
            f.load().then(() => {
                context.font = "250px Arvo-Bold"
                context.fillStyle = this.props.color === "white" ? "#000" : "#fff"
                context.textBaseline = 'top';
                context.textAlign = "center"

                const maxWidth = 2550
                const lineHeight = 350
                const x = 1644
                const y = 400

                wrapText(context, this.state.text, x, y, maxWidth, lineHeight)

                canvas.toBlob((blob) => {
                    this.setState(() => ({
                        src: window.URL.createObjectURL(blob)
                    }))
                }, "image/jpeg", 0.1)
            })
        }
    }


    handleSave = () => { // arrow for correct "this"
        this.canvas.current.toBlob((blob) => {
            let text = this.state.text.toLowerCase()
            text = text.replace(/[^a-zA-Z ]/g, "")
            text = text.replace(/ /g, "-")

            const getPosition = (string, subString, index) => {
                return string.split(subString, index).join(subString).length;
            }

            if (text.length > 18) {
                const pos = getPosition(text, "-", 4)
                text = text.substring(0, pos)
            }
            text = text + "-" + this.state._id.substring(0, 8)
            saveAs(blob, `${text}.png`)
        }, "image/png")
        // console.log(this.canvas);

    }

    handleOpen = () => {
        const w = window.open("", "null", "toolbar=no,scrollbars=no,location=no");
        this.canvas.current.toBlob((blob) => {
            const img = new Image()
            img.src = window.URL.createObjectURL(blob)
            w.document.body.innerHTML = (img.outerHTML);
        })
    }

    handleRemove = () => {
        this.props.removeCard(this.state._id)
    }

    copyText = () => {
        navigator.clipboard.writeText(this.state.text)
    }

    render() {
        const { text, _id } = this.state

        return (
            <div className="Card">
                <ContextMenuTrigger id={`image-${_id.replace(" ", "-")}`}>
                    <canvas
                        ref={this.canvas}
                        width={"3288px"}
                        height={"4488px"}
                    >
                    </canvas>
                    <img src={this.state.src} alt={text} />
                </ContextMenuTrigger>
                <ContextMenu className={this.props.color} id={`image-${_id.replace(" ", "-")}`}>
                    <MenuItem className="safety" onClick={this.handleSave}>
                        Save
                    </MenuItem>
                    <MenuItem onClick={this.handleOpen}>
                        Open in new tab
                    </MenuItem>
                    <MenuItem onClick={this.copyText}>
                        Copy text
                    </MenuItem>
                    <MenuItem divider />
                    <MenuItem className="danger" onClick={this.handleRemove}>
                        Remove
                    </MenuItem>
                </ContextMenu>
            </div>
        )
    }
}

const wrapText = (context, text, x, y, maxWidth, lineHeight) => {
    const words = text.split(' ')
    let line = ''

    for (let n = 0; n < words.length; n++) {
        const testLine = line + words[n] + ' '
        const metrics = context.measureText(testLine)
        const testWidth = metrics.width
        if (testWidth > maxWidth && n > 0) {
            context.fillText(line, x, y)
            line = words[n] + ' '
            y += lineHeight
        }
        else {
            line = testLine
        }
    }
    context.fillText(line, x, y)
}


export default Card
